@font-face {
  font-family: 'CeraPro-Regular';
  src: url('fonts/CeraPro-Regular.eot');
  src: url('fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Regular.woff2') format('woff2'),
    url('fonts/CeraPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro-Thin';
  src: url('fonts/CeraPro-Thin.eot');
  src: url('fonts/CeraPro-Thin.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Thin.woff2') format('woff2'),
    url('fonts/CeraPro-Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro-Thin';
  src: url('fonts/CeraPro-ThinItalic.eot');
  src: url('fonts/CeraPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-ThinItalic.woff2') format('woff2'),
    url('fonts/CeraPro-ThinItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro-Light';
  src: url('fonts/CeraPro-Light.eot');
  src: url('fonts/CeraPro-Light.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Light.woff2') format('woff2'),
    url('fonts/CeraPro-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-Regular.eot');
  src: url('fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Regular.woff2') format('woff2'),
    url('fonts/CeraPro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-RegularItalic.eot');
  src: url('fonts/CeraPro-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-RegularItalic.woff2') format('woff2'),
    url('fonts/CeraPro-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-Medium.eot');
  src: url('fonts/CeraPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Medium.woff2') format('woff2'),
    url('fonts/CeraPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-MediumItalic.eot');
  src: url('fonts/CeraPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-MediumItalic.woff2') format('woff2'),
    url('fonts/CeraPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-Bold.eot');
  src: url('fonts/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Bold.woff2') format('woff2'),
    url('fonts/CeraPro-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-BoldItalic.eot');
  src: url('fonts/CeraPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-BoldItalic.woff2') format('woff2'),
    url('fonts/CeraPro-BoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro-Black';
  src: url('fonts/CeraPro-Black.eot');
  src: url('fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Black.woff2') format('woff2'),
    url('fonts/CeraPro-Black.ttf') format('truetype'),
    url('fonts/CeraPro-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Pensum';
  font-display: auto;
  src: url('serif/Pensum-Pro-Bold.eot') format('woff');
  src: url('serif/Pensum-Pro-Bold.eot?#iefix') format('embedded-opentype'),
    url('serif/Pensum-Pro-Bold.woff2') format('woff2'),
    url('serif/Pensum-Pro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Pensum-Black';
  font-display: auto;
  src: url('serif/Pensum-Pro-Black.eot') format('woff');
  src: url('serif/Pensum-Pro-Black.eot?#iefix') format('embedded-opentype'),
    url('serif/Pensum-Pro-Black.woff2') format('woff2'),
    url('serif/Pensum-Pro-Black.woff') format('woff');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'Pensum';
  font-display: auto;
  src: url('serif/Pensum-Pro-Regular.woff') format('woff');
  src: url('serif/Pensum-Pro-Regular.eot?#iefix') format('embedded-opentype'),
    url('serif/Pensum-Pro-Regular.woff2') format('woff2'),
    url('serif/Pensum-Pro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'CeraPro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.hasLeftBorder {
  border-left: 2px solid #000;
}

.hasRightBorder {
  border-right: 2px solid #000;
}

.hasTopBorder {
  border-top: 2px solid #000;
}

.hasBottomBorder {
  border-bottom: 2px solid #000;
}

.boxThing{
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  box-shadow: inset 0px 0px 0px 3px #00000010;
}

.hasTopRightRadius{
  border-top-right-radius: 6px;
}

.hasTopLeftRadius{
  border-top-left-radius: 6px;
}

.hasBottomLeftRadius{
  border-bottom-left-radius: 6px;
}

.hasBottomRightRadius{
  border-bottom-right-radius: 6px;
}

.underlaySelected{
  background: #11A5E920 !important;
  border-color: #11A5E9 !important;
}
.swiper {
  /* width: 100%; */
  /* height: 100%; */
}

.swiper-slide {
  /* text-align: center; */
  /* font-size: 18px; */
  /* background: #fff; */

  /* Center slide text vertically */
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; */
}
